import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetails from "@/components/service-details-app";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import SEO from "@/components/seo";
import HeaderOne from "@/components/header-one";

const AppDevelopment = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - App -Entwicklung"
          description="Entdecken Sie die App Development Services von Solxk.Wir sind darauf spezialisiert, robuste und benutzerfreundliche Anwendungen zu erstellen, die auf Ihre Geschäftsanforderungen zugeschnitten sind."
        />
        <Layout PageTitle="AppDevelopment">
          <HeaderOne />
          <PageBanner title="App -Entwicklung" name="Service" />
          <ServiceDetails />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default AppDevelopment;
